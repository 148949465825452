import React, { useEffect, useState } from 'react';
import { Container, Modal, Spinner, Button, Row, Col, Form } from 'react-bootstrap';
import {
    getBookingDetails,
    useBookingState,
    useAccountState,
    getTripSettings,
    getVehicleAssignments,
    useVehicleAssignmentsState,
    useInvoicesState,
    getInvoiceDetails,
} from '../../../../Context';
import { formatToFixed } from '../../../../utils/helper';
import { sumBy } from 'lodash';
import Loading from '../../../../Components/Loading';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './style.scss';
import { Link } from 'react-router-dom';
dayjs.extend(utc);
dayjs.extend(timezone);

const BookingDetails = (props) => {
    const bookingId = props.match.params.id;
    const {
        bookingsDispatch,
        bookings: { bookingDetails, addonAssignments, dispatches },
    } = useBookingState();
    const {
        invoiceDispatch,
        invoice: { invoiceDetails, errorMessage },
    } = useInvoicesState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);
    const { vehicleAssignmentsDispatch } = useVehicleAssignmentsState();
    const [bookingVehicleAssignments, setBookingVehicleAssignments] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            const bookingData = await getBookingDetails(bookingsDispatch, bookingId);
            const data = await getVehicleAssignments(vehicleAssignmentsDispatch, bookingId);
            setBookingVehicleAssignments(data);
            if (bookingData?.details.invoiceID) {
                await getInvoiceDetails(invoiceDispatch, bookingData.details.invoiceID);
            }

            setLoading(false);
        })();
    }, [bookingsDispatch, bookingId, AccountsDispatch, vehicleAssignmentsDispatch]);

    const onVehicleClick = (item) => {
        props.history.push(`/bookings-vehicle-assignment/${item.id}?bookingId=${bookingId}`);
    };

    const calculateTotals = (dispatchAss, addOnAss) => {
        let totalOfDispatchAssignments = sumBy(dispatchAss, (a) => parseFloat(a.vehiclePrice));
        let totalOfAddOnAssignments = sumBy(addOnAss, (a) => parseFloat(a.addOnPrice ? a.addOnPrice : 0));
        return totalOfDispatchAssignments + totalOfAddOnAssignments;
    };

    let total = calculateTotals(dispatches, addonAssignments);
    bookingDetails.depositAmount = formatToFixed(bookingDetails.depositAmount);
    bookingDetails.totalBeforeTax = formatToFixed(total);
    bookingDetails.totalTax = formatToFixed(
        bookingDetails.salesTaxPercentage ? (total * parseFloat(bookingDetails.salesTaxPercentage)) / 100 : 0
    );
    bookingDetails.totalAfterTax = formatToFixed(
        parseFloat(bookingDetails.totalBeforeTax) + parseFloat(bookingDetails.totalTax)
    );

    return (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                <div style={{ display: 'grid', gap: '2px' }}>
                    <Button
                        onClick={() => {
                            props.history.push(`/bookings`);
                        }}
                        style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    >
                        <i className="fas fa-chevron-left" /> BACK TO BOOKINGS
                    </Button>
                    {bookingDetails?.amountOutstanding &&
                    bookingDetails.amountOutstanding > 0 &&
                    !bookingDetails.disablePayment &&
                    bookingDetails.paymentTermID &&
                    Number(bookingDetails.paymentTermID) === 1 ? (
                        <Button
                            onClick={() => {
                                window.open(
                                    `${window.location.origin}/p/booking-payment?bid=${bookingId}&ts=${new Date(bookingDetails?.createdAt).valueOf()}`,
                                    '_blank'
                                );
                            }}
                            variant="info"
                        >
                            MAKE PAYMENT
                        </Button>
                    ) : (
                        ''
                    )}
                    {bookingDetails.showDriverDetailsButton && (
                        <Button
                            onClick={() => props.history.push(`/bookings/driver-details/${bookingId}`)}
                            style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                        >
                            DRIVER DETAILS
                        </Button>
                    )}
                </div>

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">Trip Details</span>
                    </div>
                </div>
                <div className="col-12 ">
                    {bookingDetails.tripReference ? (
                        <span className="dtail">Trip Reference: {bookingDetails.tripReference}</span>
                    ) : null}
                    {bookingDetails.groupName ? (
                        <span className="dtail">Group Name: {bookingDetails.groupName}</span>
                    ) : null}
                    {bookingDetails.bookingQuoteId ? (
                        <span className="dtail">Associated Quote ID #: {bookingDetails.bookingQuoteId}</span>
                    ) : null}
                    {bookingDetails.purchaseOrder ? (
                        <span className="dtail">Purchase Order #: {bookingDetails.purchaseOrder}</span>
                    ) : null}
                </div>

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">Financial</span>
                    </div>
                </div>
                <div className="col-12 ">
                    {bookingDetails?.invoiceID && (
                        <Row>
                            <Col>
                                <Form.Label>View Invoice :</Form.Label>
                                <Link to={`/invoices/${bookingDetails.invoiceID}`}>
                                    <span className="font-weight-bold ml-2">
                                        {invoiceDetails?.invoiceNumber}
                                        <i className="fa fa-link ml-2" />
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                    )}
                    {bookingDetails.paymentStatus ? (
                        <span className="dtail">Payment Status: {bookingDetails.paymentStatus}</span>
                    ) : null}
                    {bookingDetails.depositDueDate ? (
                        <span className="dtail">Deposit Due Date: {bookingDetails.depositDueDate}</span>
                    ) : null}
                    {bookingDetails.depositAmount ? (
                        <span className="dtail">Deposit Amount: {bookingDetails.depositAmount}</span>
                    ) : null}
                    {bookingDetails.finalPaymentDueDate ? (
                        <span className="dtail">Final Payment Due Date: {bookingDetails.finalPaymentDueDate}</span>
                    ) : null}
                    <span className="dtail">Total Before Tax: {bookingDetails.totalBeforeTax || 0}</span>
                    <span className="dtail">Total Tax: {bookingDetails.totalTax || 0}</span>
                    <span className="dtail">Amount Paid: {bookingDetails.amountPaid || 0}</span>
                    <span className="dtail">Total After Tax: {bookingDetails.totalAfterTax || 0}</span>
                    <span className="dtail">Amount Outstanding: {bookingDetails.amountOutstanding || 0}</span>
                </div>

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">Routes</span>
                    </div>
                </div>
                {bookingVehicleAssignments.map((item) => (
                    <div
                        className="d-flex list-div mt-2"
                        onClick={() => {
                            onVehicleClick(item);
                        }}
                    >
                        <div className="col-6">
                            <div className={`color-line`} style={{ background: tripSettings?.mainColor }}></div>
                            <div className="card-l">
                                <span className="card-lt">{item.bookingId}</span>
                                <span className="card-lb">{item.routeDescription}</span>
                            </div>
                        </div>
                        <div className="col-6 text-right pl-2">
                            <div className="card-r">
                                <span className="card-rb">
                                    <span className="ttl">Pick-Up</span> {item.firstDepartureTime}
                                </span>
                                <span className="card-rb">
                                    <span className="ttl">Drop-of</span> {item.lastArrivalTime}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}

                {loading && <Loading loading={loading} />}

                {loading && (
                    <Container className="mb-5 text-center">
                        <Modal show={true} animation={false} className="loading-modal text-center">
                            <Spinner animation="border" variant="primary" />
                        </Modal>
                    </Container>
                )}
            </div>
        </div>
    );
};

export default BookingDetails;
