import React, { useEffect } from 'react';
import { Button, Container, Modal, Spinner } from 'react-bootstrap';

import {
    getListAddOns,
    getListVehicleSegments,
    getVehicleAssignmentDetails,
    useVehicleAssignmentsState,
    useAccountState,
    getTripSettings,
} from '../../../../Context';
import { getListDispatch, getListDispatchDocuments } from '../../../../Context/actions/vehicleAssignmentsActions';
import { formatCurrency, convertQueryStringToObject } from '../../../../utils/helper';
import './index.scss';
import moment from 'moment';

const BookingVehicleAssignments = (props) => {
    const vehicleAssignmentId = props.match.params.id;
    const queryObj = convertQueryStringToObject(props.location.search);
    const {
        vehicleAssignments: { vehicleAssignmentDetail, segments, addOns, documents, vehicles, loading },
        vehicleAssignmentsDispatch,
    } = useVehicleAssignmentsState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();

    useEffect(() => {
        (async () => {
            await getTripSettings(AccountsDispatch);
            await getVehicleAssignmentDetails(vehicleAssignmentsDispatch, queryObj.bookingId, vehicleAssignmentId);
            await getListVehicleSegments(vehicleAssignmentsDispatch, vehicleAssignmentId);
            await getListAddOns(vehicleAssignmentsDispatch, queryObj.bookingId, vehicleAssignmentId);
            await getListDispatch(vehicleAssignmentsDispatch, queryObj.bookingId, vehicleAssignmentId);
            await getListDispatchDocuments(vehicleAssignmentsDispatch, vehicleAssignmentId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filenameRender = (item) => {
        let content = <p>{item.fileName}</p>;
        if (item.fileUrl)
            content = (
                <a href={item.fileUrl} rel="noreferrer noopener" target="_blank">
                    {item.fileName}
                </a>
            );
        return content;
    };

    const currencyViewRenderer = (item, key) => {
        if (isNaN(item[key])) return '';
        return `$${formatCurrency(item[key])}`;
    };

    return (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                <Button
                    onClick={() => {
                        props.history.push(`/bookings/${queryObj.bookingId}`);
                    }}
                    style={{ backgroundColor: `${tripSettings?.buttonColor}`, borderColor: 'transparent' }}
                >
                    <i className="fas fa-chevron-left" /> BACK TO BOOKING DETAILS
                </Button>

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">{'Trip Details'}</span>
                    </div>
                </div>
                <div className="col-12 ">
                    {vehicleAssignmentDetail.routeDescription && (
                        <span className="dtail">Route Description: {vehicleAssignmentDetail.routeDescription}</span>
                    )}
                </div>
                <div className="m-2">
                    <div className="d-flex head-div mt-2" style={{ background: tripSettings?.mainColor }}>
                        <div className="col-6 item-col">
                            <div className="card-l">
                                <span className="sg-card-lt">{'location'}</span>
                            </div>
                        </div>
                        <div className="col-4 item-col">
                            <div className="card-r">
                                <span className="sg-card-rt">{'departure'}</span>
                            </div>
                        </div>
                        <div className="col-2 item-col">
                            <div className="card-l">
                                <span className="sg-card-lt">{'Miles'}</span>
                            </div>
                        </div>
                    </div>
                    {segments.filter((data) =>
                        data.isLiveSegment === 0 || data.liveHoursMiles === 0 ? false : true
                    ) &&
                        segments
                            .filter((data) => (data.isLiveSegment === 0 || data.liveHoursMiles === 0 ? false : true))
                            .map((segment) => (
                                <div className="d-flex oe-list">
                                    <div className="col-6 item-col">
                                        <div className="card-l">
                                            <span className="sg">{segment.startAddress}</span>
                                        </div>
                                    </div>
                                    <div className="col-4 item-col">
                                        <div className="card-r">
                                            <span className="sg">
                                                {moment(segment.departureTime, 'MM-DD-YYYY HH:mm').format(
                                                    'HH:mm (MM/DD)'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-2 item-col">
                                        <div className="card-l">
                                            <span className="sg">{segment.miles}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                </div>

                {vehicles.length > 0 ? (
                    <>
                        <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                            <div>
                                <span className="sg-card-lt">{'Vehicles'}</span>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="d-flex head-div mt-2" style={{ background: tripSettings?.mainColor }}>
                                <div className="col-6 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'Vehicle type'}</span>
                                    </div>
                                </div>
                                <div className="col-3 item-col">
                                    <div className="card-r">
                                        <span className="sg-card-rt">{'Price'}</span>
                                    </div>
                                </div>
                                <div className="col-3 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'Tax'}</span>
                                    </div>
                                </div>
                            </div>
                            {vehicles &&
                                vehicles.map((segment) => (
                                    <div className="d-flex oe-list">
                                        <div className="col-6 item-col">
                                            <div className="card-l">
                                                <span className="sg">{segment.vehicleDisplayName}</span>
                                            </div>
                                        </div>
                                        <div className="col-3 item-col">
                                            <div className="card-r">
                                                <span className="sg">
                                                    {currencyViewRenderer(segment, 'vehiclePrice')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-3 item-col">
                                            <div className="card-l">
                                                <span className="sg">{currencyViewRenderer(segment, 'salesTax')}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                ) : (
                    ''
                )}

                {addOns.length > 0 ? (
                    <>
                        <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                            <div>
                                <span className="sg-card-lt">{'Addons'}</span>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="d-flex head-div mt-2" style={{ background: tripSettings?.mainColor }}>
                                <div className="col-6 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'Description'}</span>
                                    </div>
                                </div>
                                <div className="col-3 item-col">
                                    <div className="card-r">
                                        <span className="sg-card-rt">{'Price'}</span>
                                    </div>
                                </div>
                                <div className="col-3 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'Tax'}</span>
                                    </div>
                                </div>
                            </div>
                            {addOns &&
                                addOns.map((addon) => (
                                    <div className="d-flex oe-list">
                                        <div className="col-6 item-col">
                                            <div className="card-l">
                                                <span className="sg">{`${addon.description} (${addon.quantity})`}</span>
                                            </div>
                                        </div>
                                        <div className="col-3 item-col">
                                            <div className="card-r">
                                                <span className="sg">{currencyViewRenderer(addon, 'price')}</span>
                                            </div>
                                        </div>
                                        <div className="col-3 item-col">
                                            <div className="card-l">
                                                <span className="sg">{currencyViewRenderer(addon, 'salesTax')}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                ) : (
                    ''
                )}

                {documents.length > 0 ? (
                    <>
                        <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                            <div>
                                <span className="sg-card-lt">{'Dispatch Documents'}</span>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="d-flex head-div mt-2" style={{ background: tripSettings?.mainColor }}>
                                <div className="col-6 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'File Name'}</span>
                                    </div>
                                </div>
                                <div className="col-6 item-col">
                                    <div className="card-r">
                                        <span className="sg-card-rt">{'Description'}</span>
                                    </div>
                                </div>
                            </div>
                            {documents &&
                                documents.map((doc) => (
                                    <div className="d-flex oe-list">
                                        <div className="col-6 item-col">
                                            <div className="card-l">
                                                <span className="sg">{filenameRender(doc)}</span>
                                            </div>
                                        </div>
                                        <div className="col-6 item-col">
                                            <div className="card-r">
                                                <span className="sg">{doc.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                ) : (
                    ''
                )}

                {loading && (
                    <Container className="mb-5 text-center">
                        <Modal show={true} animation={false} className="loading-modal text-center">
                            <Spinner animation="border" variant="primary" />
                        </Modal>
                    </Container>
                )}
            </div>
        </div>
    );
};

export default BookingVehicleAssignments;
